import { useContext, useDebugValue } from 'react'
import { SplitContext } from '@splitsoftware/splitio-react'
import SplitIO from '@splitsoftware/splitio/types/splitio'
import useTenant from '@/hooks/useTenant'

export enum SPLITS {
  USE_GOOGLE = 'GoogleGeocoding',
  USE_NEW_SAVED_ADDRESSED = 'NewGmSavedAddresses',
  REMOVE_ITEM_NOTES = 'removeItemNotes',
  USE_NEW_HEADCOUNT_FILTER = 'GMHeadcountFilter',
  BUDGET_CODE_MANDATORY = 'GmMandatoryBudgetCode',
  EAT_FIRST_BRANDING = 'GmEatFirstBranding',
  AUTOPILOT = 'autopilot',
  FLEXINVOICING = 'FlexInvoicing',
  SAME_DAY_GM_DELIVERY = 'sameDayGmDelivery',
  ORDER_TRACKING_MAP_ENABLED = 'orderTrackingMapEnabled',
  RESELLER_MODEL = 'ResellerModel',
  DYNAMIC_NAVIGATION = 'DynamicNavigation',
  IRELAND_IS_LIVE = 'IrelandLive',
  PRICES_EX_TAX_GM = 'showPricesExcludingTaxGm',
  FEEDR_INVOICING_RESELLER = 'feedrInvoicingReseller',
  FEEDR_INVOICING_RESELLER_V2 = 'reseller_v2',
  PRICES_EX_TAX_MENU_MANAGER = 'menuManagerPricesExTax',
  DEFERRED_PAYMENT = 'deferGmPayments',
  FILTER_UPCOMING_ORDERS = 'vpUpcomingOrdersDateFilter',
  CHECKOUT_INTEGRATED_LOGIN_GM = 'integratedLoginGmCheckout',
  EXPRESS_CHECKOUT_GM = 'gmExpressCheckout',
  COUNTRY_SPECIFIC_FILTERS_GM = 'countrySpecificFiltersGm',
  FIVE_STAR_RATINGS = 'useFiveStarRatings',
  SHOW_HIGHLIGHTS_GM = 'showHighlightsGm',
  SHOW_DIVERSITY_TAGS_GM = 'showDiversityTagsGm',
  MENU_ITEM_MUTATIONS_VP = 'useMenuItemMutationsVendorPortal',
  MENU_ITEM_QUERIES_VP = 'menuManagerUseMenuItemQueriesVendorPortal',
  CATERING_ORDER_DUPLICATION = 'CateringOrderDuplication',
  MIN_ORDER_VALUE_EX_TAX = 'MinOrderValueExTax',
  MENU_ITEM_GROUPING_VP = 'menuItemGroupingVp',
  MENU_ITEM_GROUPING = 'menuItemGrouping',
  SHOW_UPDATED_ONBOARDING_LINKS = 'showUpdatedOnboardingLinks',
  CUSTOMER_AUTO_INVOICE = 'customerAutoInvoice',
  ACCOUNT_REFERENCE_FIELDS = 'AccountReferenceFields',
  USE_SYNCHRONOUS_CC_ORDER_PLACEMENT = 'useSynchronousCanteenOrderPlacementWeb',
  USE_GM_VENDOR_MENU_REDESIGN_AUG_2024 = 'useGmVendorMenuRedesignAugust2024',
  GM_ITEM_CATEGORY_ARRAY = 'GMItemCategoryArray',
  USE_SERVER_SIDE_CANTEEN_MENU_CLOSED_LOGIC = 'useServerSideCanteenMenuClosedLogic',
  CUSTOMER_SPLIT_INVOICE = 'customerSplitInvoice',
  GM_CUTOFF_5PM = 'gmCutOff5pm',
  IA_REFUND_CATERING_ORDER_CARD = 'iaRefundCateringOrderCard',
  CUSTOMER_INVOICE_PER_ORDER_RECIPIENT_TYPE = 'customerInvoicePerOrderRecipientType',
  MUI_GM_ORDER_VIEW_VP = 'useMuiGmOrderViewVP',
  CUSTOM_GM_ITEMS = 'allowCustomGmItems',
  CUSTOMER_ADMIN_REPORTING = 'customerAdminReporting',
  ENTERPRISE_DISCOUNTS = 'EnterpriseDiscounts',
  USE_PANTRY_VENDOR_TYPE_DROPDOWN = 'UsePantryVendorTypeDropdown',
  SPEND_BY_VENDOR_REPORTING_TABLE_AND_GRAPH = 'SpendByVendorReportingTableAndGraph',
  SPEND_BY_LOCATIONSERVICE_REPORTING_TABLE_AND_GRAPH = 'SpendByLocationServiceReportingTableAndGraph',
  TENANT_TRANSPORT_TYPES = 'TenantTransportTypes',
  USER_STANDING_ORDER_EDIT_ENABLED = 'userStandingOrderEditEnabled',
  ALL_ORDERS_REPORTING_TABLE = 'AllOrdersReportingTable',
  REFERENCE_FIELD_COLUMNS_CUSTOMER_CREATE_INVOICE = 'ReferenceFieldColumnsCustomerCreateInvoice',
  INTERNAL_ADMIN_SSO = 'InternalAdminSSO',
  GM_INTERNAL_ADMIN_ORDER_NOTES = 'GmInternalAdminOrderNotes',
  APPORVE_SUPPLIERS = 'ApprovedSuppliers',
  GM_SHOW_FULL_ADDRESS = 'gmShowFullAddress',
  USER_SEND_QUOTE_MODAL = 'UserSendQuoteModal',
  ACCOUNT_CREDIT_CARD_FEE_TOGGLE = 'AccountCreditCardFeeToggle',
  REFRESHED_ACOUNT_FINANCE_SETTINGS_INVOICE_LIST = 'RefreshedAccountFinanceSettingsInvoiceList',
  ACCOUNT_FINANCE_INVOICE_ACCOUNT_AUTO_PAY_TOGGLE = 'AccountFinanceInvoiceAccountAutoPayToggle',
  GM_REDESIGN_JAN_2025 = 'gmCategoryTabsJan2025',
  MULTI_QUOTES = 'MultiQuotes',
  SUPPLIER_CANNOT_DELIVER_TO_ADDRESS_MODAL_INTERNAL_ADMIN = 'SupplierCannotDeliverToAddressModalInternalAdmin',
  USE_CUSTOM_ROW_ID_GM_IA_ITEM_EDITOR = 'UseCustomRowIdGmIAItemEditor',
  INCLUDE_CUSTOMER_REFUND_TO_ORDER_TOTAL = 'includeCustomerRefundToOrderTotal',
  INCLUDE_VENDOR_CHARGE_TO_ORDER_TOTAL = 'includeVendorChargeToOrderTotal',
  CATERDESK_ADMIN = 'caterdeskAdmin',
  DISPLAY_MARGIN = 'displayMargin',
  USE_DYNAMIC_MARKETPLACE_HEADER_IMAGES = 'useDynamicMarketplaceHeaderImages',
  EMMA_EDIT_RESPONSE = 'editEmmaResponse',
  MULTIPLE_BASKETS_GM_MARKETPLACE = 'multipleBasketsOnGmMarketplace',
  MANDATORY_REFERENCE_FIELDS_DISCLAIMER_MODAL_INTERNAL_ADMIN = 'MandatoryReferenceFieldsDisclaimerModalInternalAdmin',
  CUSTOMER_STANDING_ORDER_INVOICING_RULES = 'customerStandingOrderInvoicingRules',
  MULTI_SELECT_ORDER_TYPES_INTERNAL_ADMIN = 'MultiSelectOrderTypesInternalAdmin',
  MARKETPLACE_COUNTRY_DROPDOWN = 'marketplaceCountryDropdown',
  NEW_MARKETPLACE_SIGNED_IN_DROPDOWN = 'NewMarketplaceSignedInDropdown',
  ADVANCED_STANDING_ORDER_INVOICING_SETTINGS = 'advancedStandingOrderInvoicingSettings',
  AUTOFILL_BASKET_FROM_EMPTY_EMMA_QUOTE = 'AutofillBasketFromEmptyEmmaQuote',
  EMMA_BUDGET_THRESHOLD = 'EmmaBelowBudgetThresholdQuoteForm',
  VENDOR_PORTAL_ORDER_LIST_IMPROVEMENTS = 'VendorPortalOrderListImprovements',
}

export const useFeatureFlag = (treatmentName: SPLITS, attributes: SplitIO.Attributes = {}) => {
  const { client } = useContext(SplitContext)
  const tenant = useTenant()

  const treatmentValue = client?.getTreatment(treatmentName, { ...attributes, tenantId: tenant.id })
  useDebugValue(`${treatmentName}: ${treatmentValue}`)
  if (!client) return false

  return treatmentValue === 'on'
}
